@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/fedf2590f65a5730-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/c7470619e3882fe7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/bd4fdd36d94c3349-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/21bd6fd9e559ed8a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/a1b1fe1695c8a0a0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/13e0014e443920ad-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__manrope_51b8ac';
src: url(/_next/static/media/b3116da298432cf5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__manrope_Fallback_51b8ac';src: local("Arial");ascent-override: 102.74%;descent-override: 28.91%;line-gap-override: 0.00%;size-adjust: 103.76%
}.__className_51b8ac {font-family: '__manrope_51b8ac', '__manrope_Fallback_51b8ac'
}.__variable_51b8ac {--font-manrope: '__manrope_51b8ac', '__manrope_Fallback_51b8ac'
}

